define("@aboveproperty/dynamic-template/index", ["exports", "@aboveproperty/dynamic-template/services/dynamic-template", "@aboveproperty/dynamic-template/utils/register-component-for-dynamic-template"], function (_exports, _dynamicTemplate, _registerComponentForDynamicTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Object.defineProperty(_exports, "dynamicTemplate", {
    enumerable: true,
    get: function get() {
      return _dynamicTemplate.default;
    }
  });
  var _default = _exports.default = _registerComponentForDynamicTemplate.default;
});