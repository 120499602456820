define("@aboveproperty/dynamic-template/utils/register-component-for-dynamic-template", ["exports", "@glimmer/manager", "@ember/template-compilation"], function (_exports, _manager, _templateCompilation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _toConsumableArray(r) { return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread(); }
  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _iterableToArray(r) { if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r); }
  function _arrayWithoutHoles(r) { if (Array.isArray(r)) return _arrayLikeToArray(r); }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  var dynamicTemplate = function dynamicTemplate(owner, originalFactory, Component, namespace, className) {
    var _owner$lookup;
    var key = namespace ? "".concat(namespace, ".").concat(className) : className;
    var customTemplateConfig = (_owner$lookup = owner.lookup('config:dynamic-component-template')) === null || _owner$lookup === void 0 ? void 0 : _owner$lookup[key];
    if (customTemplateConfig !== null && customTemplateConfig !== void 0 && customTemplateConfig.template) {
      if (customTemplateConfig !== null && customTemplateConfig !== void 0 && customTemplateConfig.css) {
        return (0, _templateCompilation.compileTemplate)("<style>".concat(customTemplateConfig.css, "</style>") + customTemplateConfig.template);
      } else {
        return (0, _templateCompilation.compileTemplate)(customTemplateConfig.template);
      }
    }
    if (customTemplateConfig !== null && customTemplateConfig !== void 0 && customTemplateConfig.css) {
      var cssFactory = (0, _templateCompilation.compileTemplate)("<style>".concat(customTemplateConfig.css, "</style>"));
      return function (owner) {
        // compile the css as a template and insert it before the blocks compiled template
        var parsedCss = cssFactory(owner);
        var parsedTemplate = originalFactory(owner);
        parsedTemplate.parsedLayout.block[0] = [].concat(_toConsumableArray(parsedCss.parsedLayout.block[0]), _toConsumableArray(parsedTemplate.parsedLayout.block[0]));
        return parsedTemplate;
      };
    }
    return originalFactory;
  };
  var dynamicTemplateFactory = function dynamicTemplateFactory(originalFactory, ComponentClass, namespace, className) {
    return function (owner) {
      return dynamicTemplate(owner, originalFactory, ComponentClass, namespace, className)(owner);
    };
  };
  var registerComponentForDynamicTemplate = function registerComponentForDynamicTemplate(originalFactory, ComponentClass, namespace, className) {
    return (0, _manager.setComponentTemplate)(dynamicTemplateFactory(originalFactory, ComponentClass, namespace, className), ComponentClass);
  };
  var _default = _exports.default = registerComponentForDynamicTemplate;
});