define("@ember-data/legacy-compat/-private", ["exports", "@ember-data/legacy-compat/fetch-manager-f9fdbd6d"], function (_exports, _fetchManagerF9fdbd6d) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "FetchManager", {
    enumerable: true,
    get: function get() {
      return _fetchManagerF9fdbd6d.F;
    }
  });
  Object.defineProperty(_exports, "SaveOp", {
    enumerable: true,
    get: function get() {
      return _fetchManagerF9fdbd6d.a;
    }
  });
  Object.defineProperty(_exports, "Snapshot", {
    enumerable: true,
    get: function get() {
      return _fetchManagerF9fdbd6d.b;
    }
  });
  Object.defineProperty(_exports, "SnapshotRecordArray", {
    enumerable: true,
    get: function get() {
      return _fetchManagerF9fdbd6d.S;
    }
  });
});