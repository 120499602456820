define("@ember-data/legacy-compat/index", ["exports", "@ember/debug", "@ember-data/legacy-compat/fetch-manager-f9fdbd6d"], function (_exports, _debug, _fetchManagerF9fdbd6d) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.LegacyNetworkHandler = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  function _findHasMany(adapter, store, identifier, link, relationship, options) {
    var promise = Promise.resolve().then(function () {
      var snapshot = store._fetchManager.createSnapshot(identifier, options);
      var useLink = !link || typeof link === 'string';
      var relatedLink = useLink ? link : link.href;
      return adapter.findHasMany(store, snapshot, relatedLink, relationship);
    });
    promise = (0, _fetchManagerF9fdbd6d.g)(promise, store);
    promise = promise.then(function (adapterPayload) {
      var record = store._instanceCache.getRecord(identifier);
      if (!(0, _fetchManagerF9fdbd6d._)(record)) {
        {
          (false && !(false) && (0, _debug.deprecate)("A Promise for fetching ".concat(relationship.type, " did not resolve by the time your model was destroyed. This will error in a future release."), false, {
            id: 'ember-data:rsvp-unresolved-async',
            until: '5.0',
            for: '@ember-data/store',
            since: {
              available: '4.5',
              enabled: '4.5'
            }
          }));
        }
      }
      (false && !((0, _fetchManagerF9fdbd6d.p)(adapterPayload)) && (0, _debug.assert)("You made a 'findHasMany' request for a ".concat(identifier.type, "'s '").concat(relationship.key, "' relationship, using link '").concat(link, "' , but the adapter's response did not have any data"), (0, _fetchManagerF9fdbd6d.p)(adapterPayload)));
      var modelClass = store.modelFor(relationship.type);
      var serializer = store.serializerFor(relationship.type);
      var payload = (0, _fetchManagerF9fdbd6d.n)(serializer, store, modelClass, adapterPayload, null, 'findHasMany');
      (false && !('data' in payload && Array.isArray(payload.data)) && (0, _debug.assert)("fetched the hasMany relationship '".concat(relationship.name, "' for ").concat(identifier.type, ":").concat(identifier.id, " with link '").concat(link, "', but no data member is present in the response. If no data exists, the response should set { data: [] }"), 'data' in payload && Array.isArray(payload.data)));
      payload = syncRelationshipDataFromLink(store, payload, identifier, relationship);
      return store._push(payload, true);
    }, null, "DS: Extract payload of '".concat(identifier.type, "' : hasMany '").concat(relationship.type, "'"));
    {
      var record = store._instanceCache.getRecord(identifier);
      promise = (0, _fetchManagerF9fdbd6d.c)(promise, (0, _fetchManagerF9fdbd6d.d)(_fetchManagerF9fdbd6d._, record));
    }
    return promise;
  }
  function _findBelongsTo(store, identifier, link, relationship, options) {
    var promise = Promise.resolve().then(function () {
      var adapter = store.adapterFor(identifier.type);
      (false && !(adapter) && (0, _debug.assert)("You tried to load a belongsTo relationship but you have no adapter (for ".concat(identifier.type, ")"), adapter));
      (false && !(typeof adapter.findBelongsTo === 'function') && (0, _debug.assert)("You tried to load a belongsTo relationship from a specified 'link' in the original payload but your adapter does not implement 'findBelongsTo'", typeof adapter.findBelongsTo === 'function'));
      var snapshot = store._fetchManager.createSnapshot(identifier, options);
      var useLink = !link || typeof link === 'string';
      var relatedLink = useLink ? link : link.href;
      return adapter.findBelongsTo(store, snapshot, relatedLink, relationship);
    });
    {
      var record = store._instanceCache.getRecord(identifier);
      promise = (0, _fetchManagerF9fdbd6d.g)(promise, store);
      promise = (0, _fetchManagerF9fdbd6d.c)(promise, (0, _fetchManagerF9fdbd6d.d)(_fetchManagerF9fdbd6d._, record));
    }
    promise = promise.then(function (adapterPayload) {
      {
        var _record = store._instanceCache.getRecord(identifier);
        if (!(0, _fetchManagerF9fdbd6d._)(_record)) {
          (false && !(false) && (0, _debug.deprecate)("A Promise for fetching ".concat(relationship.type, " did not resolve by the time your model was destroyed. This will error in a future release."), false, {
            id: 'ember-data:rsvp-unresolved-async',
            until: '5.0',
            for: '@ember-data/store',
            since: {
              available: '4.5',
              enabled: '4.5'
            }
          }));
        }
      }
      var modelClass = store.modelFor(relationship.type);
      var serializer = store.serializerFor(relationship.type);
      var payload = (0, _fetchManagerF9fdbd6d.n)(serializer, store, modelClass, adapterPayload, null, 'findBelongsTo');
      (false && !('data' in payload && (payload.data === null || _typeof(payload.data) === 'object' && !Array.isArray(payload.data))) && (0, _debug.assert)("fetched the belongsTo relationship '".concat(relationship.name, "' for ").concat(identifier.type, ":").concat(identifier.id, " with link '").concat(link, "', but no data member is present in the response. If no data exists, the response should set { data: null }"), 'data' in payload && (payload.data === null || _typeof(payload.data) === 'object' && !Array.isArray(payload.data))));
      if (!payload.data && !payload.links && !payload.meta) {
        return null;
      }
      payload = syncRelationshipDataFromLink(store, payload, identifier, relationship);
      return store._push(payload, true);
    }, null, "DS: Extract payload of ".concat(identifier.type, " : ").concat(relationship.type));
    return promise;
  }

  // sync
  // iterate over records in payload.data
  // for each record
  //   assert that record.relationships[inverse] is either undefined (so we can fix it)
  //     or provide a data: {id, type} that matches the record that requested it
  //   return the relationship data for the parent
  function syncRelationshipDataFromLink(store, payload, parentIdentifier, relationship) {
    // ensure the right hand side (incoming payload) points to the parent record that
    // requested this relationship
    var relationshipData = payload.data ? (0, _fetchManagerF9fdbd6d.i)(payload.data, function (data, index) {
      var id = data.id,
        type = data.type;
      ensureRelationshipIsSetToParent(data, parentIdentifier, store, relationship, index);
      return {
        id: id,
        type: type
      };
    }) : null;
    var relatedDataHash = {};
    if ('meta' in payload) {
      relatedDataHash.meta = payload.meta;
    }
    if ('links' in payload) {
      relatedDataHash.links = payload.links;
    }
    if ('data' in payload) {
      relatedDataHash.data = relationshipData;
    }

    // now, push the left hand side (the parent record) to ensure things are in sync, since
    // the payload will be pushed with store._push
    var parentPayload = {
      id: parentIdentifier.id,
      type: parentIdentifier.type,
      relationships: _defineProperty({}, relationship.key, relatedDataHash)
    };
    if (!Array.isArray(payload.included)) {
      payload.included = [];
    }
    payload.included.push(parentPayload);
    return payload;
  }
  function ensureRelationshipIsSetToParent(payload, parentIdentifier, store, parentRelationship, index) {
    var id = payload.id,
      type = payload.type;
    if (!payload.relationships) {
      payload.relationships = {};
    }
    var relationships = payload.relationships;
    var inverse = getInverse(store, parentIdentifier, parentRelationship, type);
    if (inverse) {
      var inverseKey = inverse.inverseKey,
        kind = inverse.kind;
      var relationshipData = relationships[inverseKey] && relationships[inverseKey].data;
      if (kind !== 'hasMany' || typeof relationshipData !== 'undefined') {
        relationships[inverseKey] = relationships[inverseKey] || {};
        relationships[inverseKey].data = fixRelationshipData(relationshipData, kind, parentIdentifier);
      }
    }
  }
  function metaIsRelationshipDefinition(meta) {
    return typeof meta._inverseKey === 'function';
  }
  function inverseForRelationship(store, identifier, key) {
    var _definition$options, _definition$options2;
    var definition = store.getSchemaDefinitionService().relationshipsDefinitionFor(identifier)[key];
    if (!definition) {
      return null;
    }
    {
      if (metaIsRelationshipDefinition(definition)) {
        var modelClass = store.modelFor(identifier.type);
        return definition._inverseKey(store, modelClass);
      }
    }
    (false && !(((_definition$options = definition.options) === null || _definition$options === void 0 ? void 0 : _definition$options.inverse) === null || typeof ((_definition$options2 = definition.options) === null || _definition$options2 === void 0 ? void 0 : _definition$options2.inverse) === 'string' && definition.options.inverse.length > 0) && (0, _debug.assert)("Expected the relationship defintion to specify the inverse type or null.", ((_definition$options = definition.options) === null || _definition$options === void 0 ? void 0 : _definition$options.inverse) === null || typeof ((_definition$options2 = definition.options) === null || _definition$options2 === void 0 ? void 0 : _definition$options2.inverse) === 'string' && definition.options.inverse.length > 0));
    return definition.options.inverse;
  }
  function getInverse(store, parentIdentifier, parentRelationship, type) {
    var lhs_relationshipName = parentRelationship.name;
    var parentType = parentIdentifier.type;
    var inverseKey = inverseForRelationship(store, {
      type: parentType
    }, lhs_relationshipName);
    if (inverseKey) {
      var definition = store.getSchemaDefinitionService().relationshipsDefinitionFor({
        type: type
      });
      var kind = definition[inverseKey].kind;
      return {
        inverseKey: inverseKey,
        kind: kind
      };
    }
  }
  function relationshipDataPointsToParent(relationshipData, identifier) {
    if (relationshipData === null) {
      return false;
    }
    if (Array.isArray(relationshipData)) {
      if (relationshipData.length === 0) {
        return false;
      }
      for (var i = 0; i < relationshipData.length; i++) {
        var entry = relationshipData[i];
        if (validateRelationshipEntry(entry, identifier)) {
          return true;
        }
      }
    } else {
      return validateRelationshipEntry(relationshipData, identifier);
    }
    return false;
  }
  function fixRelationshipData(relationshipData, relationshipKind, _ref) {
    var id = _ref.id,
      type = _ref.type;
    var parentRelationshipData = {
      id: id,
      type: type
    };
    var payload;
    if (relationshipKind === 'hasMany') {
      payload = relationshipData || [];
      if (relationshipData) {
        // these arrays could be massive so this is better than filter
        // Note: this is potentially problematic if type/id are not in the
        // same state of normalization.
        var found = relationshipData.find(function (v) {
          return v.type === parentRelationshipData.type && v.id === parentRelationshipData.id;
        });
        if (!found) {
          payload.push(parentRelationshipData);
        }
      } else {
        payload.push(parentRelationshipData);
      }
    } else {
      payload = relationshipData || {};
      Object.assign(payload, parentRelationshipData);
    }
    return payload;
  }
  function validateRelationshipEntry(_ref2, _ref3) {
    var id = _ref2.id;
    var parentModelID = _ref3.id;
    return id && id.toString() === parentModelID;
  }
  var PotentialLegacyOperations = new Set(['findRecord', 'findAll', 'query', 'queryRecord', 'findBelongsTo', 'findHasMany', 'updateRecord', 'createRecord', 'deleteRecord']);
  var LegacyNetworkHandler = _exports.LegacyNetworkHandler = {
    request: function request(context, next) {
      // if we are not a legacy request, move on
      if (context.request.url || !context.request.op || !PotentialLegacyOperations.has(context.request.op)) {
        return next(context.request);
      }
      var store = context.request.store;
      if (!store._fetchManager) {
        store._fetchManager = new _fetchManagerF9fdbd6d.F(store);
      }
      switch (context.request.op) {
        case 'findRecord':
          return findRecord(context);
        case 'findAll':
          return findAll(context);
        case 'query':
          return query(context);
        case 'queryRecord':
          return queryRecord(context);
        case 'findBelongsTo':
          return findBelongsTo(context);
        case 'findHasMany':
          return findHasMany(context);
        case 'updateRecord':
          return saveRecord(context);
        case 'createRecord':
          return saveRecord(context);
        case 'deleteRecord':
          return saveRecord(context);
        default:
          return next(context.request);
      }
    }
  };
  function findBelongsTo(context) {
    var _context$request = context.request,
      store = _context$request.store,
      data = _context$request.data,
      identifiers = _context$request.records;
    var options = data.options,
      record = data.record,
      links = data.links,
      useLink = data.useLink,
      field = data.field;
    var identifier = identifiers === null || identifiers === void 0 ? void 0 : identifiers[0];

    // short circuit if we are already loading
    var pendingRequest = identifier && store._fetchManager.getPendingFetch(identifier, options);
    if (pendingRequest) {
      return pendingRequest;
    }
    if (useLink) {
      return _findBelongsTo(store, record, links.related, field, options);
    }
    (false && !(Array.isArray(identifiers) && identifiers.length === 1) && (0, _debug.assert)("Expected an identifier", Array.isArray(identifiers) && identifiers.length === 1));
    var manager = store._fetchManager;
    (0, _fetchManagerF9fdbd6d.e)(identifier);
    return options.reload ? manager.scheduleFetch(identifier, options, context.request) : manager.fetchDataIfNeededForIdentifier(identifier, options, context.request);
  }
  function findHasMany(context) {
    var _context$request2 = context.request,
      store = _context$request2.store,
      data = _context$request2.data,
      identifiers = _context$request2.records;
    var options = data.options,
      record = data.record,
      links = data.links,
      useLink = data.useLink,
      field = data.field;

    // link case
    if (useLink) {
      var adapter = store.adapterFor(record.type);
      /*
      If a relationship was originally populated by the adapter as a link
      (as opposed to a list of IDs), this method is called when the
      relationship is fetched.
       The link (which is usually a URL) is passed through unchanged, so the
      adapter can make whatever request it wants.
       The usual use-case is for the server to register a URL as a link, and
      then use that URL in the future to make a request for the relationship.
      */
      (false && !(adapter) && (0, _debug.assert)("You tried to load a hasMany relationship but you have no adapter (for ".concat(record.type, ")"), adapter));
      (false && !(typeof adapter.findHasMany === 'function') && (0, _debug.assert)("You tried to load a hasMany relationship from a specified 'link' in the original payload but your adapter does not implement 'findHasMany'", typeof adapter.findHasMany === 'function'));
      return _findHasMany(adapter, store, record, links.related, field, options);
    }

    // identifiers case

    var fetches = new Array(identifiers.length);
    var manager = store._fetchManager;
    for (var i = 0; i < identifiers.length; i++) {
      var identifier = identifiers[i];
      // TODO we probably can be lenient here and return from cache for the isNew case
      (0, _fetchManagerF9fdbd6d.e)(identifier);
      fetches[i] = options.reload ? manager.scheduleFetch(identifier, options, context.request) : manager.fetchDataIfNeededForIdentifier(identifier, options, context.request);
    }
    return Promise.all(fetches);
  }
  function saveRecord(context) {
    var _context$request3 = context.request,
      store = _context$request3.store,
      data = _context$request3.data,
      operation = _context$request3.op;
    var options = data.options,
      identifier = data.record;
    var saveOptions = Object.assign(_defineProperty({}, _fetchManagerF9fdbd6d.a, operation), options);
    var fetchManagerPromise = store._fetchManager.scheduleSave(identifier, saveOptions);
    return fetchManagerPromise.then(function (payload) {
      var result;
      /*
      // TODO @runspired re-evaluate the below claim now that
      // the save request pipeline is more streamlined.
       Note to future spelunkers hoping to optimize.
      We rely on this `run` to create a run loop if needed
      that `store._push` and `store.saveRecord` will both share.
       We use `join` because it is often the case that we
      have an outer run loop available still from the first
      call to `store._push`;
      */
      store._join(function () {
        //We first make sure the primary data has been updated
        var cache = store._instanceCache.getResourceCache(identifier);
        result = cache.didCommit(identifier, {
          request: context.request,
          content: payload
        });
        if (payload && payload.included) {
          store._push({
            data: null,
            included: payload.included
          }, true);
        }
      });
      return store.peekRecord(result.data);
    }).catch(function (e) {
      var err = e;
      if (!e) {
        err = new Error("Unknown Error Occurred During Request");
      } else if (typeof e === 'string') {
        err = new Error(e);
      }
      adapterDidInvalidate(store, identifier, err);
      throw err;
    });
  }
  function adapterDidInvalidate(store, identifier, error) {
    if (error && error.isAdapterError === true && error.code === 'InvalidError') {
      var serializer = store.serializerFor(identifier.type);

      // TODO @deprecate extractErrors being called
      // TODO remove extractErrors from the default serializers.
      if (serializer && typeof serializer.extractErrors === 'function') {
        var errorsHash = serializer.extractErrors(store, store.modelFor(identifier.type), error, identifier.id);
        error.errors = errorsHashToArray(errorsHash);
      }
    }
    var cache = store._instanceCache.getResourceCache(identifier);
    if (error.errors) {
      (false && !(typeof cache.getErrors === 'function') && (0, _debug.assert)("Expected the cache in use by resource ".concat(String(identifier), " to have a getErrors(identifier) method for retreiving errors."), typeof cache.getErrors === 'function'));
      var jsonApiErrors = error.errors;
      if (jsonApiErrors.length === 0) {
        jsonApiErrors = [{
          title: 'Invalid Error',
          detail: '',
          source: {
            pointer: '/data'
          }
        }];
      }
      cache.commitWasRejected(identifier, jsonApiErrors);
    } else {
      cache.commitWasRejected(identifier);
    }
  }
  function makeArray(value) {
    return Array.isArray(value) ? value : [value];
  }
  var PRIMARY_ATTRIBUTE_KEY = 'base';
  function errorsHashToArray(errors) {
    var out = [];
    if (errors) {
      Object.keys(errors).forEach(function (key) {
        var messages = makeArray(errors[key]);
        for (var i = 0; i < messages.length; i++) {
          var title = 'Invalid Attribute';
          var pointer = "/data/attributes/".concat(key);
          if (key === PRIMARY_ATTRIBUTE_KEY) {
            title = 'Invalid Document';
            pointer = "/data";
          }
          out.push({
            title: title,
            detail: messages[i],
            source: {
              pointer: pointer
            }
          });
        }
      });
    }
    return out;
  }
  function findRecord(context) {
    var _context$request4 = context.request,
      store = _context$request4.store,
      data = _context$request4.data;
    var identifier = data.record,
      options = data.options;
    var promise;

    // if not loaded start loading
    if (!store._instanceCache.recordIsLoaded(identifier)) {
      promise = store._fetchManager.fetchDataIfNeededForIdentifier(identifier, options, context.request);

      // Refetch if the reload option is passed
    } else if (options.reload) {
      (0, _fetchManagerF9fdbd6d.e)(identifier);
      promise = store._fetchManager.scheduleFetch(identifier, options, context.request);
    } else {
      var snapshot = null;
      var adapter = store.adapterFor(identifier.type);

      // Refetch the record if the adapter thinks the record is stale
      if (typeof options.reload === 'undefined' && adapter.shouldReloadRecord && adapter.shouldReloadRecord(store, snapshot = store._fetchManager.createSnapshot(identifier, options))) {
        (0, _fetchManagerF9fdbd6d.e)(identifier);
        {
          options.reload = true;
          promise = store._fetchManager.scheduleFetch(identifier, options, context.request);
        }
      } else {
        // Trigger the background refetch if backgroundReload option is passed
        if (options.backgroundReload !== false && (options.backgroundReload || !adapter.shouldBackgroundReloadRecord || adapter.shouldBackgroundReloadRecord(store, snapshot = snapshot || store._fetchManager.createSnapshot(identifier, options)))) {
          (0, _fetchManagerF9fdbd6d.e)(identifier);
          {
            options.backgroundReload = true;
            void store._fetchManager.scheduleFetch(identifier, options, context.request);
          }
        }

        // Return the cached record
        promise = Promise.resolve(identifier);
      }
    }
    return promise.then(function (identifier) {
      return store.peekRecord(identifier);
    });
  }
  function findAll(context) {
    var _context$request5 = context.request,
      store = _context$request5.store,
      data = _context$request5.data;
    var type = data.type,
      options = data.options;
    var adapter = store.adapterFor(type);
    (false && !(adapter) && (0, _debug.assert)("You tried to load all records but you have no adapter (for ".concat(type, ")"), adapter));
    (false && !(typeof adapter.findAll === 'function') && (0, _debug.assert)("You tried to load all records but your adapter does not implement 'findAll'", typeof adapter.findAll === 'function')); // avoid initializing the liveArray just to set `isUpdating`
    var maybeRecordArray = store.recordArrayManager._live.get(type);
    var snapshotArray = new _fetchManagerF9fdbd6d.S(store, type, options);
    var shouldReload = options.reload || options.reload !== false && (adapter.shouldReloadAll && adapter.shouldReloadAll(store, snapshotArray) || !adapter.shouldReloadAll && snapshotArray.length === 0);
    var fetch;
    if (shouldReload) {
      maybeRecordArray && (maybeRecordArray.isUpdating = true);
      fetch = _findAll(adapter, store, type, snapshotArray, context.request, true);
    } else {
      fetch = Promise.resolve(store.peekAll(type));
      if (options.backgroundReload || options.backgroundReload !== false && (!adapter.shouldBackgroundReloadAll || adapter.shouldBackgroundReloadAll(store, snapshotArray))) {
        maybeRecordArray && (maybeRecordArray.isUpdating = true);
        void _findAll(adapter, store, type, snapshotArray, context.request, false);
      }
    }
    return fetch;
  }
  function _findAll(adapter, store, type, snapshotArray, request, isAsyncFlush) {
    var schema = store.modelFor(type);
    var promise = Promise.resolve().then(function () {
      return adapter.findAll(store, schema, null, snapshotArray);
    });
    promise = (0, _fetchManagerF9fdbd6d.g)(promise, store);
    promise = promise.then(function (adapterPayload) {
      (false && !((0, _fetchManagerF9fdbd6d.p)(adapterPayload)) && (0, _debug.assert)("You made a 'findAll' request for '".concat(type, "' records, but the adapter's response did not have any data"), (0, _fetchManagerF9fdbd6d.p)(adapterPayload)));
      var serializer = store.serializerFor(type);
      var payload = (0, _fetchManagerF9fdbd6d.n)(serializer, store, schema, adapterPayload, null, 'findAll');
      store._push(payload, isAsyncFlush);
      snapshotArray._recordArray.isUpdating = false;
      return snapshotArray._recordArray;
    });
    return promise;
  }
  function query(context) {
    var _context$request6 = context.request,
      store = _context$request6.store,
      data = _context$request6.data;
    var options = data.options;
    var type = data.type,
      query = data.query;
    var adapter = store.adapterFor(type);
    (false && !(adapter) && (0, _debug.assert)("You tried to make a query but you have no adapter (for ".concat(type, ")"), adapter));
    (false && !(typeof adapter.query === 'function') && (0, _debug.assert)("You tried to make a query but your adapter does not implement 'query'", typeof adapter.query === 'function'));
    var recordArray = options._recordArray || store.recordArrayManager.createArray({
      type: type,
      query: query
    });
    {
      delete options._recordArray;
    }
    var schema = store.modelFor(type);
    var promise = Promise.resolve().then(function () {
      return adapter.query(store, schema, query, recordArray, options);
    });
    promise = (0, _fetchManagerF9fdbd6d.g)(promise, store);
    return promise.then(function (adapterPayload) {
      var serializer = store.serializerFor(type);
      var payload = (0, _fetchManagerF9fdbd6d.n)(serializer, store, schema, adapterPayload, null, 'query');
      var identifiers = store._push(payload, true);
      (false && !(Array.isArray(identifiers)) && (0, _debug.assert)('The response to store.query is expected to be an array but it was a single record. Please wrap your response in an array or use `store.queryRecord` to query for a single record.', Array.isArray(identifiers)));
      store.recordArrayManager.populateManagedArray(recordArray, identifiers, payload);
      return recordArray;
    });
  }
  function assertSingleResourceDocument(payload) {
    (false && !(!Array.isArray(payload.data)) && (0, _debug.assert)("Expected the primary data returned by the serializer for a 'queryRecord' response to be a single object or null but instead it was an array.", !Array.isArray(payload.data)));
  }
  function queryRecord(context) {
    var _context$request7 = context.request,
      store = _context$request7.store,
      data = _context$request7.data;
    var type = data.type,
      query = data.query,
      options = data.options;
    var adapter = store.adapterFor(type);
    (false && !(adapter) && (0, _debug.assert)("You tried to make a query but you have no adapter (for ".concat(type, ")"), adapter));
    (false && !(typeof adapter.queryRecord === 'function') && (0, _debug.assert)("You tried to make a query but your adapter does not implement 'queryRecord'", typeof adapter.queryRecord === 'function'));
    var schema = store.modelFor(type);
    var promise = Promise.resolve().then(function () {
      return adapter.queryRecord(store, schema, query, options);
    });
    promise = (0, _fetchManagerF9fdbd6d.g)(promise, store);
    return promise.then(function (adapterPayload) {
      var serializer = store.serializerFor(type);
      var payload = (0, _fetchManagerF9fdbd6d.n)(serializer, store, schema, adapterPayload, null, 'queryRecord');
      assertSingleResourceDocument(payload);
      var identifier = store._push(payload, true);
      return identifier ? store.peekRecord(identifier) : null;
    });
  }
});