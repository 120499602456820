define("ember-power-select/components/power-select/search-message", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <ul class="ember-power-select-options" role="listbox" ...attributes>
    <li class="ember-power-select-option ember-power-select-option--search-message" role="option" aria-selected={{false}}>
      {{@searchMessage}}
    </li>
  </ul>
  */
  {
    "id": "BySs5LRc",
    "block": "[[[11,\"ul\"],[24,0,\"ember-power-select-options\"],[24,\"role\",\"listbox\"],[17,1],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"ember-power-select-option ember-power-select-option--search-message\"],[14,\"role\",\"option\"],[15,\"aria-selected\",false],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@searchMessage\"],false,[]]",
    "moduleName": "ember-power-select/components/power-select/search-message.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});