define("@ember-data/store/-private", ["exports", "@ember-data/store/index-36c7ac56"], function (_exports, _index36c7ac) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "AdapterPopulatedRecordArray", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.f;
    }
  });
  Object.defineProperty(_exports, "CacheHandler", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.C;
    }
  });
  Object.defineProperty(_exports, "IDENTIFIER_ARRAY_TAG", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.j;
    }
  });
  Object.defineProperty(_exports, "IdentifierArray", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.I;
    }
  });
  Object.defineProperty(_exports, "MUTATE", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.M;
    }
  });
  Object.defineProperty(_exports, "RecordArray", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.I;
    }
  });
  Object.defineProperty(_exports, "RecordArrayManager", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.R;
    }
  });
  Object.defineProperty(_exports, "SOURCE", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.h;
    }
  });
  Object.defineProperty(_exports, "Store", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.S;
    }
  });
  Object.defineProperty(_exports, "_clearCaches", {
    enumerable: true,
    get: function get() {
      return _index36c7ac._;
    }
  });
  Object.defineProperty(_exports, "coerceId", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.e;
    }
  });
  Object.defineProperty(_exports, "fastPush", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.k;
    }
  });
  Object.defineProperty(_exports, "isStableIdentifier", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.i;
    }
  });
  Object.defineProperty(_exports, "normalizeModelName", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.n;
    }
  });
  Object.defineProperty(_exports, "notifyArray", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.g;
    }
  });
  Object.defineProperty(_exports, "peekCache", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.p;
    }
  });
  Object.defineProperty(_exports, "recordIdentifierFor", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.r;
    }
  });
  Object.defineProperty(_exports, "removeRecordDataFor", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.l;
    }
  });
  Object.defineProperty(_exports, "setIdentifierForgetMethod", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.c;
    }
  });
  Object.defineProperty(_exports, "setIdentifierGenerationMethod", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.a;
    }
  });
  Object.defineProperty(_exports, "setIdentifierResetMethod", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.d;
    }
  });
  Object.defineProperty(_exports, "setIdentifierUpdateMethod", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.b;
    }
  });
  Object.defineProperty(_exports, "storeFor", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.s;
    }
  });
});