define("@ember-data/store/index", ["exports", "@ember-data/store/index-36c7ac56"], function (_exports, _index36c7ac) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "CacheHandler", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.C;
    }
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.S;
    }
  });
  Object.defineProperty(_exports, "normalizeModelName", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.n;
    }
  });
  Object.defineProperty(_exports, "recordIdentifierFor", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.r;
    }
  });
  Object.defineProperty(_exports, "setIdentifierForgetMethod", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.c;
    }
  });
  Object.defineProperty(_exports, "setIdentifierGenerationMethod", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.a;
    }
  });
  Object.defineProperty(_exports, "setIdentifierResetMethod", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.d;
    }
  });
  Object.defineProperty(_exports, "setIdentifierUpdateMethod", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.b;
    }
  });
  Object.defineProperty(_exports, "storeFor", {
    enumerable: true,
    get: function get() {
      return _index36c7ac.s;
    }
  });
});