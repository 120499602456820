define("@ember-data/adapter/serialize-into-hash-b9092b25", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.a = setupFastboot;
  _exports.b = serializeIntoHash;
  _exports.d = determineBodyPromise;
  _exports.g = getFetchFunction;
  _exports.p = parseResponseHeaders;
  _exports.s = serializeQueryParams;
  function _slicedToArray(r, e) { return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
  function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(r) { if (Array.isArray(r)) return r; }
  function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
  var newline = /\r?\n/;
  function parseResponseHeaders(headersString) {
    var headers = Object.create(null);
    if (!headersString) {
      return headers;
    }
    var headerPairs = headersString.split(newline);
    for (var i = 0; i < headerPairs.length; i++) {
      var header = headerPairs[i];
      var j = 0;
      var foundSep = false;
      for (; j < header.length; j++) {
        if (header.charCodeAt(j) === 58 /* ':' */) {
          foundSep = true;
          break;
        }
      }
      if (foundSep === false) {
        continue;
      }
      var field = header.substring(0, j).trim();
      var value = header.substring(j + 1, header.length).trim();
      if (value) {
        var lowerCasedField = field.toLowerCase();
        headers[lowerCasedField] = value;
        headers[field] = value;
      }
    }
    return headers;
  }
  function continueOnReject(promise) {
    return Promise.resolve(promise).catch(function (e) {
      return e;
    });
  }

  /*
   * Function that always attempts to parse the response as json, and if an error is thrown,
   * returns `undefined` if the response is successful and has a status code of 204 (No Content),
   * or 205 (Reset Content) or if the request method was 'HEAD', and the plain payload otherwise.
   */
  function _determineContent(response, requestData, payload) {
    var ret = payload;
    var error = null;
    if (!response.ok) {
      return payload;
    }
    var status = response.status;
    var payloadIsEmpty = payload === '' || payload === null;
    var statusIndicatesEmptyResponse = status === 204 || status === 205 || requestData.method === 'HEAD';
    if (response.ok && (statusIndicatesEmptyResponse || payloadIsEmpty)) {
      return;
    }
    try {
      ret = JSON.parse(payload);
    } catch (e) {
      if (!(e instanceof SyntaxError)) {
        return e;
      }
      e.payload = payload;
      error = e;
    }
    if (error) {
      return error;
    }
    return ret;
  }
  function determineBodyPromise(response, requestData) {
    // response.text() may resolve or reject
    // it is a native promise, may not have finally
    return continueOnReject(response.text()).then(function (payload) {
      return _determineContent(response, requestData, payload);
    });
  }
  var RBRACKET = /\[\]$/;
  function isPlainObject(obj) {
    return Object.prototype.toString.call(obj) === '[object Object]';
  }

  /*
   * Helper function that turns the data/body of a request into a query param string.
   * This is directly copied from jQuery.param.
   */
  function serializeQueryParams(queryParamsObject) {
    var s = [];
    function buildParams(prefix, obj) {
      var i, len, key;
      if (prefix) {
        if (Array.isArray(obj)) {
          for (i = 0, len = obj.length; i < len; i++) {
            if (RBRACKET.test(prefix)) {
              add(s, prefix, obj[i]);
            } else {
              buildParams(prefix + '[' + (_typeof(obj[i]) === 'object' && obj[i] !== null ? i : '') + ']', obj[i]);
            }
          }
        } else if (isPlainObject(obj)) {
          for (key in obj) {
            buildParams(prefix + '[' + key + ']', obj[key]);
          }
        } else {
          add(s, prefix, obj);
        }
      } else if (Array.isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          add(s, obj[i].name, obj[i].value);
        }
      } else {
        for (key in obj) {
          buildParams(key, obj[key]);
        }
      }
      return s;
    }
    return buildParams('', queryParamsObject).join('&');
  }

  /*
   * Part of the `serializeQueryParams` helper function.
   */
  function add(s, k, v) {
    // Strip out keys with undefined value and replace null values with
    // empty strings (mimics jQuery.ajax)
    if (v === undefined) {
      return;
    } else if (v === null) {
      v = '';
    }
    v = typeof v === 'function' ? v() : v;
    s[s.length] = "".concat(encodeURIComponent(k), "=").concat(encodeURIComponent(v));
  }
  var _fetch = null;
  var REQUEST = null;
  function getFetchFunction() {
    // return cached fetch function
    if (_fetch !== null) {
      return _fetch();
    }

    // grab browser native fetch if available, or global fetch if otherwise configured
    if (typeof fetch === 'function') {
      // fallback to using global fetch
      _fetch = function _fetch() {
        return fetch;
      };

      /* global FastBoot */
      // grab fetch from node-fetch
    } else if (typeof FastBoot !== 'undefined') {
      try {
        var nodeFetch = FastBoot.require('node-fetch');
        var httpRegex = /^https?:\/\//;
        var protocolRelativeRegex = /^\/\//;

        // eslint-disable-next-line no-inner-declarations
        function parseRequest(request) {
          if (request === null) {
            throw new Error("Trying to fetch with relative url but the application hasn't finished loading FastBootInfo, see details at https://github.com/ember-cli/ember-fetch#relative-url");
          }
          // Old Prember version is not sending protocol
          var protocol = request.protocol === 'undefined:' ? 'http:' : request.protocol;
          return [request.get('host'), protocol];
        }

        // eslint-disable-next-line no-inner-declarations
        function buildAbsoluteUrl(url) {
          if (protocolRelativeRegex.test(url)) {
            var _parseRequest = parseRequest(REQUEST),
              _parseRequest2 = _slicedToArray(_parseRequest, 1),
              host = _parseRequest2[0];
            url = host + url;
          } else if (!httpRegex.test(url)) {
            var _parseRequest3 = parseRequest(REQUEST),
              _parseRequest4 = _slicedToArray(_parseRequest3, 2),
              _host = _parseRequest4[0],
              protocol = _parseRequest4[1];
            url = protocol + '//' + _host + url;
          }
          return url;
        }

        // eslint-disable-next-line no-inner-declarations
        function patchedFetch(input, options) {
          if (input && input.href) {
            input.url = buildAbsoluteUrl(input.href);
          } else if (typeof input === 'string') {
            input = buildAbsoluteUrl(input);
          }
          return nodeFetch(input, options);
        }
        _fetch = function _fetch() {
          return patchedFetch;
        };
      } catch (e) {
        throw new Error("Unable to create a compatible 'fetch' for FastBoot with node-fetch");
      }
    }
    (false && !(_fetch) && (0, _debug.assert)("Cannot find a 'fetch' global and did not detect FastBoot.", _fetch));
    return _fetch();
  }
  function setupFastboot(fastBootRequest) {
    REQUEST = fastBootRequest;
  }
  function serializeIntoHash(store, modelClass, snapshot) {
    var options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {
      includeId: true
    };
    var serializer = store.serializerFor(modelClass.modelName);
    (false && !(serializer) && (0, _debug.assert)("Cannot serialize record, no serializer defined", serializer));
    if (typeof serializer.serializeIntoHash === 'function') {
      var data = {};
      serializer.serializeIntoHash(data, modelClass, snapshot, options);
      return data;
    }
    return serializer.serialize(snapshot, options);
  }
});